import request from './request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/riskPlan/queryWIthPage',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/riskPlan/add',
        method: "post",
        data,
    })
}

export function bindDevice(data) {
    return request({
        url: '/model-analysis/riskPlan/bindDevice',
        method: "post",
        data,
    })
}


export function remove(data) {
    return request({
        url: '/model-analysis/riskPlan/delete',
        method: "post",
        data,
    })
}

export function fetchDetail(data) {
    return request({
        url: '/model-analysis/riskPlan/queryDetail',
        method: "post",
        data,
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/riskPlan/update',
        method: "post",
        data,
    })
}