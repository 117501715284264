<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input v-model="form.code" placeholder="编码" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="名称" />
          </a-form-model-item>

          <a-form-model-item>
            <a-space style="margin-top: 4px">
              <Button type="primary" @click="query">查询</Button>
              <Button @click="reset">重置</Button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <Button type="primary" @click="$router.push($route.path + '/add')"
              >新建</Button
            >
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding size="large" />

    <a-table
      :dataSource="list"
      @change="onChange"
      :loading="loading"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
      rowKey="id"
    >
      <a-table-column key="name" title="名称" data-index="name" />
      <a-table-column key="co4de" title="类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="riskType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column key="cod2e" title="级别" data-index="" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="riskLevel" :dictValue="text.level" />
        </template>
      </a-table-column>
      <a-table-column key="remark" title="规则">
        <template slot-scope="text">
          <span>
            <DataDictFinder
              dictType="riskPlan.rainFall"
              :dictValue="text.standard"
            />
            <DataDictFinder
              dictType="riskPlan.liquidLevel"
              :dictValue="text.standard"
            />
            {{ text.value }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="file" title="文件" align="center">
        <template slot-scope="text">
          <a :href="text.planFile" v-if="text.planFile">附件</a>
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a
              href="#"
              @click.prevent="
                $router.push($route.path + '/edit-facility?id=' + text.id)
              "
              >关联设备</a
            >
            <a
              href="#"
              @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a
            >
            <a href="#" class="danger" @click.prevent="deleteText(text)"
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/warning";
export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>
